import React, { useState } from "react";
import styled from "styled-components";
import Seo from "../components/layout/Seo";
import { __BREAKPOINTS } from "../styles/utils/variables";

const Wrapper = styled.div`
    width: 100%;
    height: 939px;
    .upper{
        width: 100%;
        height: 50%;
        background: var(--darkpurple);
        display: flex;
        align-items: center;
        flex-direction: column;
        color: white;
        position: relative;
        *{
            text-transform:uppercase;
        }
        h1{
            margin: 0;
            margin-top: 45px;
            font-size: 115px;
            font-family: "Integral CF";
            font-weight: 400;
        }
        .blurb{
            width: 713px;
            display: flex;
            flex-wrap: wrap;
            margin-top: 21px;
            .content{
                min-width: 50%;
                max-width: 50%;
                padding: 0 5px;
                font-size: 16px;
                font-family: 'Integral CF';
                text-align: center;
            }
        }
        .signupSection{
            width: 473px;
            height: 102px;
            border: 9px solid white;
            border-bottom: none;
            margin-top: 21px;
            display: flex;
            justify-content: center;
            align-items: center;
            h2{
                font-size: 73px;
                font-family: "Neumatic Compressed Bold";
            }
        }
    }
    .lower{
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        .form{
            width: 473px;
            border: 9px solid var(--darkpurple);
            border-top: none;
            height: 400px;
            .formOption{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 28px;
                label{
                    max-width: 89px;
                    min-width: 89px;
                    font-size: 14px;
                    text-align: right;
                    font-family: "Integral CF";
                    margin-left: 31px;
                    margin-right: 24px;
                }
                input{
                    width: 293px;
                    height: 40px;
                    background: rgba(97, 44, 143, 0.28);
                    margin-right: 36px;
                    border: none;
                }
            }
            .submitButton{
                width: 100%;
                display: flex;
                justify-content: right;
                margin-top: 30px;
                input{
                    width: 80%;
                    height: 40px;
                    background: rgba(97, 44, 143, 0.28);
                    font-family: "Integral CF";
                    border: none;
                    margin-right: 36px;
                    &:hover:enabled{
                        cursor:pointer;
                        background: var(--darkpurple);
                        color: white;
                    }
                }
            }
        }
    }
    @media (max-width: ${__BREAKPOINTS.sm}px){
        height: 1184px;
        .upper{
            h1{
                margin-top: 59px;
                font-size: 48px;
            }
            .blurb{
                width: 100%;
                .content{
                    min-width: 100%;
                    max-width: 100%;
                }
            }
            .signupSection{
                width: 267px;
                position: absolute;
                bottom: 10px;
            }
        }
        .lower{
            .form{
                width: 267px;
                height: 550px;
                .formOption{
                    flex-direction: column;
                    margin-top: 30px;
                    label{
                        text-align: center;
                    }
                    input{
                        width: 215px;
                        margin: 0;
                    }
                }
                .submitButton{
                    justify-content: center;
                    input{
                        margin: 0;
                    }
                }
                
            }
        }
    }
`;

export default function SignupForm(){
    const addDashes = () => {
        let f = document.getElementById("drip-number")?.value.replace(/\D/g,'').substring(0,10);
        const areaCode = f.substring(0,3);
        const middle = f.substring(3,6);
        const last = f.substring(6,10);
        console.log('VALUE', f);
        let ret = f;
        if(f.length > 6){
            ret = '('+ areaCode + ') - ' + middle + ' - ' + last;
        }
        else if(f.length > 3){
            ret = '('+ areaCode + ') - ' + middle;
        }
        setNumber(ret);
    }
    const [number, setNumber] = useState('');
    const [disable, setDisable] = useState(number === '' || document.getElementById("drip-full-name").value === '' || document.getElementById("drip-email").value === '' || document.getElementById("drip-postal").value === '');
    const checkDisable = () => {
        console.log('LENGTH', document.getElementById("drip-number")?.value.length);
        setDisable(document.getElementById("drip-number")?.value.length < 18 || document.getElementById("drip-full-name").value === '' || document.getElementById("drip-email").value === '' || document.getElementById("drip-postal").value === '');
    }
    return(
        <>
            <Seo title={"THC VIP"} />
            <div className="container">
                <Wrapper>
                    <div className="upper">
                        <h1>thc vip</h1>
                        <div className="blurb">
                            <p className="content">Exclusive Access to thc content and local events</p>
                            <p className="content">be first to know about special  product drops</p>
                            <p className="content">Special savings opportunities and social media recognition</p>
                            <p className="content">Early access to  giveaways and thc experiences</p>
                        </div>
                        <div className="signupSection">
                            <h2>sign up today</h2>
                        </div>
                    </div>
                    <div className="lower">
                        <div className="form">
                            <form action="https://www.getdrip.com/forms/308804060/submissions" method="post" data-drip-embedded-form="308804060" id="drip-ef-308804060">
                                <div data-drip-attribute="description"></div>
                                <div className="formOption">
                                    <label htmlFor="drip-full-name">First Name</label><br />
                                    <input type="text" id="drip-first-name" name="fields[first_name]"  onChange={() => checkDisable()}/>
                                </div>
                                <div className="formOption">
                                    <label htmlFor="drip-email">Email</label><br />
                                    <input type="email" id="drip-email" name="fields[email]" onChange={() => checkDisable()}/>
                                </div>
                                <div className="formOption">
                                    <label htmlFor="drip-number">Number</label><br />
                                    <input type="text" id="drip-number" name="fields[number]" onChange={() => {
                                        addDashes();
                                        checkDisable();
                                    }} value={number}/>
                                </div>
                                <div className="formOption">
                                    <label htmlFor="drip-postal">Postal</label><br />
                                    <input type="text" id="drip-postal" name="fields[postal]" onChange={() => checkDisable()}/>
                                </div>
                                <div className="submitButton">
                                    <input type="submit" value="Sign Up" data-drip-attribute="sign-up-button" disabled = {disable}/>
                                </div>
                            </form>
                        </div>
                    </div>
                </Wrapper>
            </div>
        </>
    )
}